import { useState, useEffect } from 'react';

export const useCountdown = seconds => {
  const [counter, setCounter] = useState(seconds);
  const [isRunning, setIsRunning] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isRunning && counter > 0) {
      const interval = setInterval(() => {
        setCounter(prevCounter => prevCounter - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
      // eslint-disable-next-line no-else-return
    } else if (counter === 0) {
      setIsRunning(false);
    }
  }, [isRunning, counter]);

  useEffect(() => {
    if (seconds > 0) {
      setCounter(seconds);
      setIsRunning(true);
    }
  }, [seconds]);

  return {
    counter,
    isRunning,
    start: () => setIsRunning(true),
    stop: () => setIsRunning(false),
    reset: time => setCounter(time || seconds),
  };
};
