import PropTypes from 'prop-types';
import React from 'react';

import { StyledAnchor, StyledButton } from './Anchor.styled';

const Anchor = React.forwardRef(
  ({ children, className, href, onClick }, ref) => (
    <StyledAnchor className={className} href={href} onClick={onClick} ref={ref}>
      {children}
    </StyledAnchor>
  ),
);

Anchor.Button = ({ type, className, onClick, children }) => (
  <StyledButton className={className} onClick={onClick} type={type}>
    {children}
  </StyledButton>
);

Anchor.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

Anchor.defaultProps = {
  className: undefined,
  href: undefined,
  onClick: undefined,
};

Anchor.Button.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
};

Anchor.Button.defaultProps = {
  className: undefined,
  type: 'submit',
};

export default Anchor;
