import getConfig from 'next/config';

import * as actions from '@actions/shop';
import { selectGetUID } from '@selectors/auth';

import request from '@utils/request';

import * as endpoints from '@common/endpoints';

const { PRODUCTS_BACKEND_URL } = getConfig().publicRuntimeConfig;

export const fetchCustomerShops = () => (dispatch, getState) => {
  const customerId = selectGetUID(getState());

  dispatch(actions.getCustomerShopsRequest());

  return request
    .get(endpoints.customerShops(customerId))
    .then(({ data }) => {
      dispatch(actions.getCustomerShopsSuccess(data));
    })
    .catch(error => {
      dispatch(actions.getCustomerShopsFailure(error));
    });
};

export const fetchShops = () => dispatch => {
  dispatch(actions.getShopsRequest());

  return request
    .get(endpoints.shops())
    .then(({ data }) => {
      dispatch(actions.getShopsSuccess(data));
    })
    .catch(error => {
      dispatch(actions.getShopsFailure(error));
    });
};

export const fetchShop = id => dispatch => {
  dispatch(actions.getShopRequest(id));

  return request
    .get(endpoints.shop(id))
    .then(({ data }) => {
      dispatch(actions.getShopSuccess(data));

      return data;
    })
    .catch(error => {
      dispatch(actions.getShopFailure(error));

      throw error;
    });
};

export const fetchProductColorAvailability = productColorId => dispatch => {
  dispatch(actions.getProductAvailabilityRequest(productColorId));

  return request
    .get(endpoints.productAvailability(productColorId), {
      baseURL: PRODUCTS_BACKEND_URL,
    })
    .then(({ data }) => {
      dispatch(actions.getProductAvailabilitySuccess(data, productColorId));
    })
    .catch(error => {
      dispatch(actions.getProductAvailabilityFailure(error, productColorId));
    });
};
