import styled from 'styled-components';

import { getGridBase, media } from '@utils/styled';

import Anchor from '@common/components/Anchor';
import Button from '@common/components/Button';
import Typography from '@common/components/Typography';

export const FormRow = styled.div`
  ${getGridBase(8)};
  ${({ theme }) => theme.getFluidSpacing('grid-gap', 'widthBased', 1)};
  width: 100%;
`;

export const FormColumn = styled.div`
  display: flex;
  grid-column: span 8;

  ${media.from800up`
    grid-column: span 4;
  `}
`;

export const FormFooter = styled.div`
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 3)};
`;

export const SubmitButton = styled(Button)`
  display: block;
`;

export const StyledAnchor = styled(Anchor)`
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'scale', 1)};
  display: inline-block;
`;

export const ForgottenLinksContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${StyledAnchor} {
    &:last-of-type {
      ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 2)};
    }
  }
`;

export const TooManyAttemptsWrapper = styled.div`
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 7)};
`;

export const TooManyAttemptsText = styled(Typography).attrs({
  variant: 'body2',
})`
  line-height: 1.9;
`;
