export const STEP_CONTACT_INFO = 'contact-info';
export const STEP_DOCUMENTS = 'documents';
export const STEP_ALTERNATIVES = 'alternatives';
export const STEP_SUBMITTED = 'submitted';
export const JOB_APPLY_FORM_STEP = [
  STEP_CONTACT_INFO,
  STEP_DOCUMENTS,
  STEP_ALTERNATIVES,
  STEP_SUBMITTED,
];

export const MAX_ALTERNATIVES_COUNT = 4;
export const LOCAL_STORAGE_FORM_KEY = 'jobApplicationValues';
export const LOCAL_STORAGE_FILE_KEY = 'jobApplicationFiles';
