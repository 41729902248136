import styled, { css } from 'styled-components';

import { getThemeColor } from '@utils/styled';

export const AnchorStyles = css`
  color: ${getThemeColor('hue.blue')};
  text-decoration: none;
  ${({ theme }) => theme.getFluidFont('paragraph2')};
`;

export const StyledAnchor = styled.a`
  ${AnchorStyles};
`;

export const StyledButton = styled.button`
  ${AnchorStyles};
  outline: none;
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
`;
