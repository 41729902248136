import { logout } from '@thunks/auth';

import { getLinkDataFromUrl } from '@utils/urls';
import { clearAccessToken } from '@utils/jwt';
import { flushLocalStorage } from '@utils/storage';

import { CREATE_PROFILE, PRE_LOGIN, VIP_HOME } from '@common/routes';

const NOT_LOGGED_IN_STATUS_CODE = 401;
const MISSING_CUSTOMER_STATUS_CODE = 404;

export function getRedirectPathAfterLogin(response, customTarget) {
  if (customTarget) {
    return getLinkDataFromUrl(customTarget)?.urlObject;
  }

  if (response.customerId) {
    return { pathname: VIP_HOME };
  }

  switch (response.statusCode) {
    case MISSING_CUSTOMER_STATUS_CODE:
      return { pathname: CREATE_PROFILE };
    case NOT_LOGGED_IN_STATUS_CODE:
      return { pathname: PRE_LOGIN };
    default:
      return null;
  }
}

export async function clearSession(dispatch) {
  await flushLocalStorage();
  await clearAccessToken();
  dispatch(logout());
}
