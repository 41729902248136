import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { wrapper } from '@store/store';

import Login from '@routes/Login';

import { authenticatedUserWrapper } from '@utils/authenticatedUserWrapper';

const LoginPage = () => <Login />;

export const getServerSideProps = wrapper.getServerSideProps(
  authenticatedUserWrapper(async (store, { locale }) => ({
    props: {
      ...(await serverSideTranslations(locale)),
    },
  })),
);

export default LoginPage;
