import {
  LOCAL_STORAGE_FILE_KEY,
  LOCAL_STORAGE_FORM_KEY,
} from '@common/constants/jobs';

export const MOBILE_ORDER_FORM_PREFIX = 'MOBILE_ORDER_FORM';

export const flushLocalStorage = async (
  localStorageKeys = [
    LOCAL_STORAGE_FILE_KEY,
    LOCAL_STORAGE_FORM_KEY,
    MOBILE_ORDER_FORM_PREFIX,
  ],
) => {
  Object.entries(localStorage).forEach(([key]) => {
    localStorageKeys.forEach(localStorageKey => {
      if (key.startsWith(localStorageKey)) {
        localStorage.removeItem(key);
      }
    });
  });
};
