import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { selectIsUserLogged } from '@selectors/auth';
import * as favoritesSelectors from '@selectors/favorites';
import { getUserData, login } from '@thunks/auth';
import * as customerInfoThunks from '@thunks/customerInfo';
import * as favoritesThunks from '@thunks/favorites';
import * as shopThunks from '@thunks/shop';

import { clearSession, getRedirectPathAfterLogin } from '@utils/auth';
import { getSession } from '@utils/jwt';
import { apiErrorsToFieldErrors, getErrorMessage } from '@utils/validation';
import { getRouteUrl, getLinkDataFromUrl } from '@utils/urls';
import { extractNumbersFromString } from '@utils/extractNumbersFromString';

import { VIP_HOME, FORGOT_PASSWORD, LOGIN, PRE_LOGIN } from '@common/routes';
import {
  INVALID_EMAIL_ERROR,
  REQUIRED_FIELD_ERROR,
} from '@common/constants/formValidation';
import Layout from '@common/containers/Layout';
import RouteLayout from '@common/containers/RouteLayout';
import { BUTTON_VARIANTS } from '@common/components/Button';
import { FormControl, TextInput } from '@common/components/forms';
import Typography from '@common/components/Typography';
import { useCountdown } from '@common/hooks/useCountdown';
import { usePrevious } from '@common/hooks/usePrevious';

import {
  ForgottenLinksContainer,
  FormColumn,
  FormFooter,
  FormRow,
  StyledAnchor,
  SubmitButton,
  TooManyAttemptsText,
  TooManyAttemptsWrapper,
} from './Login.styled';

const getMailtoLink = (recipientEmail, subject, body) =>
  `mailto:${recipientEmail}?subject=${subject}&body=${body}`;

export function Login() {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const router = useRouter();
  const countdown = useCountdown(0);
  const countdownRanOnce = useRef(false);
  const isUserLogged = useSelector(selectIsUserLogged);
  const favoritesLocal = useSelector(
    favoritesSelectors.selectFavoritesIdsUnauthenticated,
  );
  const hasFavoritesLocal = favoritesLocal.length > 0;
  const targetRedirect = router.query?.target;
  const pageUrl = getRouteUrl(router.locale, LOGIN);

  const friendlyError = router.query?.friendlyError;
  const previousFriendlyError = usePrevious(router.query?.friendlyError);
  const countdownFromFriendlyError =
    extractNumbersFromString(friendlyError)?.[0];

  useEffect(() => {
    const previousFriendlyErrorConditions =
      previousFriendlyError &&
      Boolean(extractNumbersFromString(friendlyError).length) &&
      Boolean(extractNumbersFromString(previousFriendlyError).length);

    const startConditions =
      countdownFromFriendlyError &&
      !countdown.isRunning &&
      !countdownRanOnce.current &&
      previousFriendlyErrorConditions;

    if (startConditions) {
      countdownRanOnce.current = true;
      countdown.reset(countdownFromFriendlyError * 60);
      countdown.start();
    }
  }, [
    countdown,
    countdownFromFriendlyError,
    previousFriendlyError,
    friendlyError,
  ]);

  useEffect(() => {
    const counterResetConditions =
      countdownRanOnce.current === true &&
      countdown.counter === 0 &&
      previousFriendlyError &&
      previousFriendlyError !== friendlyError;

    if (counterResetConditions) {
      countdownRanOnce.current = false;
    }
  }, [countdown.counter, friendlyError, previousFriendlyError]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values, formikBag) => {
      const { email, password } = values;

      formikBag.resetForm({ values });

      return dispatch(
        login({
          email,
          password,
        }),
      )
        .then(() => dispatch(getUserData()))
        .then(async data => {
          if (hasFavoritesLocal) {
            await dispatch(favoritesThunks.favorite(favoritesLocal));
          }

          dispatch(favoritesThunks.fetchFavorites());
          dispatch(shopThunks.fetchCustomerShops());
          dispatch(customerInfoThunks.fetchCustomerBadge());
          const link = getRedirectPathAfterLogin(data, targetRedirect);

          if (link) {
            await router.push(
              link,
              getRouteUrl(router.locale, link.pathname, link.query),
            );
          }
        })
        .catch(async error => {
          if (error?.response?.data) {
            if (error?.response?.data?.statusCode) {
              const link = getRedirectPathAfterLogin(
                error.response.data,
                error?.response?.data?.redirect,
              );

              if (link) {
                return router.push(
                  link.pathname,
                  getRouteUrl(router.locale, link.pathname, link.query),
                );
              }
            }

            if (error?.response?.data?.friendlyError) {
              router.replace(
                {
                  pathname: router.pathname,
                  query: { friendlyError: [error.response.data.friendlyError] },
                  shallow: true,
                },
                router.asPath,
              );
            }

            const fieldsErrors = apiErrorsToFieldErrors(
              error.response.data,
              {},
            );

            if (fieldsErrors.nonFieldErrors) {
              router.replace(
                {
                  pathname: router.pathname,
                  query: { friendlyError: fieldsErrors.nonFieldErrors },
                },
                router.asPath,
              );
            }
          }
        });
    },
    validationSchema: Login.validationSchema,
  });

  const userEmail = useMemo(
    () => formik.values.email || '«–»',
    [formik.values.email],
  );
  const forgottenEmailBody = useMemo(
    () =>
      encodeURIComponent(
        `${t('loginPage.forgottenEmailAddress.emailBody')} ${userEmail}`,
      ),
    [t, userEmail],
  );
  const forgottenEmailLink = useMemo(
    () =>
      getMailtoLink(
        t('loginPage.forgottenEmailAddress.emailAddress'),
        t('loginPage.forgottenEmailAddress.emailSubject'),
        forgottenEmailBody,
      ),
    [forgottenEmailBody, t],
  );

  const translatedCountdownLabel = useMemo(
    () =>
      t(
        'loginPage.submit.basic.cooldown',
        'Zu viele Anmeldeversuche. Du kannst es in 2 Minuten erneut probieren.',
        { cooldownTime: countdown.counter },
      ),
    [countdown.counter, t],
  );

  const { s, b, e } = useMemo(() => {
    const [sentenceStart, ...rest] = translatedCountdownLabel.split(' ');
    const boldedLabel = `${rest[0]} ${rest[1]}`;
    const [, , ...sentenceEndArray] = rest;
    const sentenceEnd = sentenceEndArray.join(' ');

    return {
      s: sentenceStart,
      b: boldedLabel,
      e: sentenceEnd,
    };
  }, [translatedCountdownLabel]);

  const getToken = useCallback(async () => getSession(), []);

  useEffect(() => {
    const token = getToken().then(data => data?.accessToken);

    if (isUserLogged && token && !formik.isSubmitting) {
      const link = (targetRedirect &&
        getLinkDataFromUrl(targetRedirect)?.urlObject) || {
        pathname: VIP_HOME,
      };

      router.push(link, getRouteUrl(router.locale, link.pathname, link.query));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLogged]);

  useEffect(() => {
    async function logout() {
      await clearSession();
    }
    const token = getToken();

    if (isUserLogged && !token) {
      logout(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLogged]);

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    isSubmitting,
  } = formik;

  return (
    <Layout
      title={t('loginPage.metaTitle', 'Login')}
      canonicalPageUrl={pageUrl}
    >
      <RouteLayout
        backRoute={PRE_LOGIN}
        backRouteAs={getRouteUrl(router.locale, PRE_LOGIN)}
        title={t('loginPage.title', 'VIP-Login')}
        stickyTitle={t('loginPage.title', 'VIP-Login')}
        columns={8}
      >
        <Typography variant="h6" as="h2">
          {t('loginPage.viaEmail', 'Via E-Mail')}
        </Typography>
        <form onSubmit={handleSubmit} noValidate>
          <FormRow>
            <FormColumn>
              <FormControl
                label={t('loginPage.emailAddress', 'E-mail Adresse')}
                hasError={touched.email && !!errors.email}
                message={touched.email && getErrorMessage(t, errors.email)}
                noSpacing
              >
                <TextInput
                  data-testid="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.email && !!errors.email}
                  type="email"
                  inputMode="email"
                  placeholder={t(
                    'loginPage.emailPlaceholder',
                    'doris.mustermann@mail.ch',
                  )}
                  disabled={isSubmitting}
                />
              </FormControl>
            </FormColumn>
            <FormColumn>
              <FormControl
                label={t('loginPage.password', 'Passwort')}
                hasError={touched.password && !!errors.password}
                message={
                  touched.password && getErrorMessage(t, errors.password)
                }
                noSpacing
              >
                <TextInput
                  data-testid="password"
                  name="password"
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={touched.password && !!errors.password}
                  type="password"
                  placeholder={t(
                    'loginPage.passwordPlaceholder',
                    'Dein aktuelles Passwort',
                  )}
                  disabled={isSubmitting}
                />
              </FormControl>
            </FormColumn>
          </FormRow>

          <FormFooter>
            <SubmitButton
              type="submit"
              disabled={isSubmitting || countdown.isRunning}
              data-testid="submit"
              variant={BUTTON_VARIANTS.LEVEL_1_GREEN}
            >
              {t('loginPage.submit.basic', 'Anmelden')}
            </SubmitButton>
            {countdown.isRunning && countdown.counter > 0 && (
              <TooManyAttemptsWrapper>
                <TooManyAttemptsText>
                  {s} <strong>{b}</strong> {e}
                </TooManyAttemptsText>
              </TooManyAttemptsWrapper>
            )}
          </FormFooter>

          <ForgottenLinksContainer>
            <Link
              legacyBehavior
              href={FORGOT_PASSWORD}
              passHref
              as={getRouteUrl(router.locale, FORGOT_PASSWORD)}
            >
              <StyledAnchor>
                {t('loginPage.passwordForgotten', 'Passwort vergessen?')}
              </StyledAnchor>
            </Link>
            <Link legacyBehavior href={forgottenEmailLink} passHref>
              <StyledAnchor>
                {t('loginPage.emailForgotten', 'E-Mail Adresse vergessen?')}
              </StyledAnchor>
            </Link>
          </ForgottenLinksContainer>
        </form>
      </RouteLayout>
    </Layout>
  );
}

Login.validationSchema = Yup.object().shape({
  email: Yup.string().email(INVALID_EMAIL_ERROR).required(REQUIRED_FIELD_ERROR),
  password: Yup.string().required(REQUIRED_FIELD_ERROR),
});

export default Login;
