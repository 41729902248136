export function extractNumbersFromString(string) {
  if (!string || typeof string !== 'string') {
    return [];
  }

  const match = string.match(/\d+/g);

  if (!match) {
    return [];
  }

  return string.match(/\d+/g).map(Number);
}
